import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Posts from '../components/posts'
import Intro from '../components/intro'
import styled from 'styled-components'

import '../styles/globals.css';

const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-top: 2rem;
  margin-bottom: 1.45rem;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
`;

function CoverDivider() {
  return (
    <FlexDiv>
      <Separator><h2>Latest Posts</h2></Separator>
    </FlexDiv>
  );
}

const Index = ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout link={location}>
      <Seo title="Blog" />
      <Intro />
      <CoverDivider />
      {posts.map(({ node }) => {
        return (
          <Posts vertex={node} key={node.id}/>
        )
      })}
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/blog/" } }
      limit: 3
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
          }
        }
      }
    }
  }
`
