import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { fileAbsolutePath: { regex: "/content/intro/" } }
            ){
            edges {
                node {
                    excerpt
                    html
                    frontmatter {
                        title
                    }
                }
            }
        }
    }`)

  const post = data.allMarkdownRemark.edges
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(0),
      }}
    >
       {post.map(({ node }) => {
        return (
          <div dangerouslySetInnerHTML={{ __html: node.html }} key={node.id} />
        )
      })}
    </div>
  )
}

export default Intro
